import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import logobranco from "../../assets/imagens/logo_branco.png"


const Footer = () => {

  let newDate = new Date()
  let year = newDate.getFullYear();

  return (
    <div className='footer-main'>
      <div className='footer-main-sec'>
        <div className='logotipo'>
          <Link to={`/`} onClick={() => smoothScrollToTop()}>
            <img
              src={logobranco}
              alt='Logotipo'
            />
          </Link>
          <div class="social-icons">
            <Link className='facebook' to={`https://www.facebook.com/indoorparkinglowcost`} target="_blank">
              <AiFillFacebook />
            </Link>
            <Link className='facebook' to={`https://www.instagram.com/indoorparkinglowcost/`} target="_blank">
              <AiFillInstagram />
            </Link>
          </div>
        </div>
        <div className='footer-contactos'>
          <h3>Contactos</h3>
          <ul>
            <li><a href='tel:+351939066037'>+351 939 066 037*</a></li>
            <li><a href='tel:+351939066038'>+351 939 066 038*</a></li>
            <li><a href='tel:+351220733834'>+351 220 733 834**</a></li>
            <br />
            <p>*(chamada para rede móvel nacional)</p>
            <p>**(chamada para rede fixa nacional)</p>
          </ul>
        </div>
        <div className='footer-localizacao'>
          <h3>Localização</h3>
          <ul>
            <li><a href="https://www.google.pt/maps/place/Indoor+Parking+Low+Cost/@41.2257169,-8.6792514,17z/data=!4m13!1m7!3m6!1s0xd24688c2a06c347:0x48a586b04bc5e2b7!2sR.+da+Telheira+89,+Santa+Cruz+do+Bpo.!3b1!8m2!3d41.2257169!4d-8.6770627!3m4!1s0xd24688dc4d2636d:0x80415d86cf41157d!8m2!3d41.2257522!4d-8.6772613" target="_blank">Rua da Telheira nº 89</a></li>
            <li><a href="https://www.google.pt/maps/place/Indoor+Parking+Low+Cost/@41.2257169,-8.6792514,17z/data=!4m13!1m7!3m6!1s0xd24688c2a06c347:0x48a586b04bc5e2b7!2sR.+da+Telheira+89,+Santa+Cruz+do+Bpo.!3b1!8m2!3d41.2257169!4d-8.6770627!3m4!1s0xd24688dc4d2636d:0x80415d86cf41157d!8m2!3d41.2257522!4d-8.6772613" target="_blank">Santa Cruz do Bispo</a></li>
            <li><a href="https://www.google.pt/maps/place/Indoor+Parking+Low+Cost/@41.2257169,-8.6792514,17z/data=!4m13!1m7!3m6!1s0xd24688c2a06c347:0x48a586b04bc5e2b7!2sR.+da+Telheira+89,+Santa+Cruz+do+Bpo.!3b1!8m2!3d41.2257169!4d-8.6770627!3m4!1s0xd24688dc4d2636d:0x80415d86cf41157d!8m2!3d41.2257522!4d-8.6772613" target="_blank">4455–562 Matosinhos</a></li>
          </ul>
        </div>
        <div className='footer-email'>
          <h3>Email</h3>
          <ul>
            <li><a href='mailto:info@indoorparkinglowcost.pt'>info@indoorparkinglowcost.pt</a></li>
          </ul>
        </div>
      </div>
      <div className='footer-copyright'>
        <div>
          <Link className="terms" to="/termos-e-condicoes" target="_blank">Termos e Condições</Link>
          <p>©{year} Indoor Parking Low Cost | Desenvolvido por <a href='https://pragmatica.pt/' target="_blank">Pragmática</a></p>
        </div>
      </div>
    </div>
  );
};

export default Footer;



function smoothScrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}